import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { ArrowBackIos, LiveTv } from "@mui/icons-material";
import LiveComments from "@/components/LiveComments";
import Hls from "hls.js";
import { artistKey } from "@/App";

export default function Viewer() {
  const videoRef = useRef(null);
  const [isHlsAvailable, setIsHlsAvailable] = useState(true);
  const [videoDuration, setVideoDuration] = useState(null); // Add state to track the video duration

  const liveStreamBase = "https://storage.googleapis.com/nb-live-staging";

  const hlsUrl = `${liveStreamBase}/${artistKey}/manifest.m3u8`;

  const fallbackVideoUrl =
    "https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

  useEffect(() => {
    let hls;

    const checkHlsAvailability = async () => {
      try {
        const response = await fetch(hlsUrl);
        if (!response.ok) {
          throw new Error("HLS stream not available");
        }
        setIsHlsAvailable(true);
      } catch (error) {
        console.error("HLS stream not available:", error);
        setIsHlsAvailable(false);
      }
    };

    checkHlsAvailability();

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", () => {
        // Get the duration and set it in state
        const duration = videoRef.current.duration;
        console.log("Video duration:", duration);
        setVideoDuration(duration);
      });

      if (isHlsAvailable) {
        if (Hls.isSupported()) {
          hls = new Hls();
          hls.loadSource(hlsUrl);
          hls.attachMedia(videoRef.current);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoRef.current.play().catch((error) => {
              console.error("Error attempting to play", error);
            });
          });
        } else if (
          videoRef.current.canPlayType("application/vnd.apple.mpegurl")
        ) {
          videoRef.current.src = hlsUrl;
          videoRef.current.addEventListener("loadedmetadata", () => {
            videoRef.current.play().catch((error) => {
              console.error("Error attempting to play", error);
            });
          });
        } else {
          console.error("This browser does not support HLS playback.");
        }
      } else {
        videoRef.current.src = fallbackVideoUrl;
        videoRef.current.loop = true;
        videoRef.current.play().catch((error) => {
          console.error("Error attempting to play fallback video", error);
        });
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [isHlsAvailable]);

  return (
    <>
      <PageAppBar videoRef={videoRef} />
      <Box sx={{ px: 2 }}>
        <Box
          sx={{
            borderRadius: "20px",
            overflow: "hidden",
            border: "1px solid #343332",
            aspectRatio: "1 / 1",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <video
            ref={videoRef}
            controls
            playsInline
            style={{
              width: "100%", // Make it responsive to the container's width
              height: "auto", // Keep height proportional
              aspectRatio: "1 / 1", // Ensure 1:1 aspect ratio
              objectFit: "cover", // Ensure video covers the container
            }}
          />
        </Box>
      </Box>
      <LiveComments />
    </>
  );
}

function PageAppBar({ videoRef }) {
  const navigate = useNavigate();

  // Function to convert seconds to a human-readable time format
  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    if (seconds < 60) {
      return `${seconds}s`;
    }
    return `${minutes}m ${seconds}s`;
  };

  // Get the video duration from the video element
  // const videoDuration = videoRef.current?.duration;
  // const [videoDuration, setVideoDuration] = useState(null);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.addEventListener("loadedmetadata", () => {
  //       // Get the duration and set it in state
  //       const duration = videoRef.current.duration;
  //       console.log("Video duration:", duration);
  //       setVideoDuration(duration);
  //     });
  //   }
  // }, [videoRef]);

  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </IconButton>
        </Box>

        <Box
          className="artist-logo"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LiveTv color="secondary" />
          <Typography
            style={{
              fontFamily: "'Montserrat Alternates', sans-serif",
              fontWeight: 700,
              fontSize: "1.4rem",
              marginBottom: "0px",
              marginLeft: "12px",
            }}
          >
            {artistKey || "notebook"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="darkgray">
            {timer ? formatDuration(timer) : "Loading..."}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
